import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default class PdfGenerator {
    constructor(data, pdfTemplate) {
        this.data = data;
        this.pdfTemplate = pdfTemplate;
        this.doc = null;
    }

    // eslint-disable-next-line no-unused-vars
    save (filename = null) {
        this.doc?.download(filename);
        return false
    }

    async getPdf (callback) {
        return await import(
            /* webpackChunkName: "lang-[request]" */
            `./pdfTemplates/${this.pdfTemplate}`
            )
            .then(async (template) => {
                this.doc =  await pdfMake.createPdf(template.default(this.data))

                this.doc.getDataUrl((dataUrl) => {
                    callback(dataUrl)
                })
            })
            .catch(err => {
                throw err;
            });
    }

    async getBuffer () {
        return new Promise(resolve => {
            this.doc?.getBuffer(resolve)
        })
    }
}
